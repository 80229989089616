var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: 'listing-add-' + _vm.updateHTML
  }, [_c('div', {
    staticClass: "sticky",
    staticStyle: {
      "display": "flex",
      "height": "64px",
      "width": "100%",
      "z-index": "10",
      "padding-left": "156px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "add-button",
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "margin-right": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_vm._v("•")])]), _c('div', {
    staticClass: "header",
    staticStyle: {
      "margin-top": "129px",
      "padding-top": "0"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("EditListing")))]), _c('p', {
    staticClass: "statusLabel content-bold",
    class: {
      clickable: _vm.edit,
      'draft-label ': _vm.form.status === 'draft',
      'active-label ': _vm.form.status === 'active',
      'sold-label ': _vm.form.status === 'sold'
    },
    attrs: {
      "id": "statusLabel"
    },
    on: {
      "click": function ($event) {
        return _vm.openStatusDropdown(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(_vm.form.status)) + " ")])]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "section-row"
  }, [_c('v-row', {
    staticClass: "subheading"
  }, [_c('div', {
    staticClass: "circle-number",
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_vm._v("1")]), _c('h5', [_vm._v(_vm._s(_vm.$t("AddProjectAndObjectDetails")))])]), _c('v-row', {
    staticClass: "autocomplete-frame",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "deal-name"
  }, [_vm._v(" " + _vm._s(_vm.form.projectName) + " ")])], 1), _c('div', [_c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Address")))])]), _c('v-row', {
    staticClass: "address-selected"
  }, [_vm.form.street ? _c('p', [_vm._v(_vm._s(_vm.form.street))]) : _vm._e(), _vm.form.houseNumber ? _c('p', [_vm._v(" ")]) : _vm._e(), _vm.form.houseNumber ? _c('p', {
    key: _vm.form.houseNumber
  }, [_vm._v(" " + _vm._s(_vm.form.houseNumber) + " ")]) : _vm._e(), _vm.form.houseNumber || _vm.form.street ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.cityPart))]), _vm.form.cityPart ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.district))]), _vm.form.district ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.city))]), _vm.form.county ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.county))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "justify-content": "space-between",
      "width": "100%",
      "gap": "12px"
    }
  }, [_c('v-col', {
    staticClass: "house-numbers",
    staticStyle: {
      "max-width": "50%"
    }
  }, [_vm.form.addressType !== 'generic' ? _c('v-row', [_vm.counties ? _c('Dropdown', {
    key: _vm.countiesUpdater,
    attrs: {
      "placeholder-text": _vm.$t('County'),
      "custom-style": 'width: 100%;height: 56px;z-index: 3;',
      "regular-size": true,
      "disabled": _vm.countiesDisabled,
      "items": _vm.counties,
      "search-box": true,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedCounty
    },
    on: {
      "itemSelected": _vm.selectCounty
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.form.addressType !== 'generic' ? _c('v-row', [_c('Dropdown', {
    key: _vm.citiesUpdater,
    attrs: {
      "custom-style": 'width: 100%;font-size: 14px; line-height: 20px; height: 56px;z-index: 2;',
      "placeholder-text": _vm.$t('City'),
      "regular-size": true,
      "disabled": _vm.citiesDisabled,
      "search-box": true,
      "items": _vm.cities,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedCity
    },
    on: {
      "itemSelected": _vm.selectCity
    }
  })], 1) : _vm._e(), _vm.form.addressType !== 'generic' ? _c('v-row', [_c('Dropdown', {
    key: _vm.districtsUpdater + 0.2,
    attrs: {
      "custom-style": 'width: 100%;height: 56px;',
      "placeholder-text": _vm.$t('District'),
      "regular-size": true,
      "disabled": _vm.districtsDisabled,
      "search-box": true,
      "items": _vm.districts,
      "display-key": 'name',
      "value-key": 'name',
      "selected": _vm.selectedDistrict
    },
    on: {
      "itemSelected": _vm.selectDistrict
    }
  })], 1) : _vm._e(), _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "street",
      "placeholder": _vm.$t('Street')
    },
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "house-number",
      "placeholder": _vm.$t('HouseNumber')
    },
    model: {
      value: _vm.form.houseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "houseNumber", $$v);
      },
      expression: "form.houseNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showHouseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showHouseNumber", $$v);
      },
      expression: "form.showHouseNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "cadastral-number",
      "placeholder": _vm.$t('CadastralNumber')
    },
    model: {
      value: _vm.form.cadastralNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cadastralNumber", $$v);
      },
      expression: "form.cadastralNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showCadastralNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showCadastralNumber", $$v);
      },
      expression: "form.showCadastralNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openCadastalLink(_vm.form.cadastralNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("OpenLandRegistry")))])])]), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "estate-number",
      "placeholder": _vm.$t('EstateNumber'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "change": function ($event) {
        return _vm.numberInputFormatWholeNumber('estateNumber');
      }
    },
    model: {
      value: _vm.form.estateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "estateNumber", $$v);
      },
      expression: "form.estateNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showEstateNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showEstateNumber", $$v);
      },
      expression: "form.showEstateNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openELandRegisterLink(_vm.form.cadastralNumber, _vm.form.estateNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("OpenELandRegister")))])])]), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "ehr-number",
      "placeholder": _vm.$t('EhrNumber'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "change": function ($event) {
        return _vm.numberInputFormatWholeNumber('ehrNumber');
      }
    },
    model: {
      value: _vm.form.ehrNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ehrNumber", $$v);
      },
      expression: "form.ehrNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-left": "2px",
      "padding": "8px 0 8px 0"
    }
  }, [_c('span', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.openEhrNumberLink(_vm.form.ehrNumber);
      }
    }
  }, [_c('img', {
    staticClass: "link-icon",
    attrs: {
      "src": require("../../../../assets/images/link.svg")
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "10px",
      "vertical-align": "middle"
    }
  }, [_vm._v(_vm._s(_vm.$t("Visit")) + " EHR")])])])], 1), _c('v-col', [_c('div', {
    attrs: {
      "id": "map"
    }
  }, [_c('GmapMap', {
    staticStyle: {
      "width": "432px",
      "height": "240px"
    },
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    },
    on: {
      "click": _vm.setMarker
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    },
    on: {
      "drag": _vm.updateMarker
    }
  })], 1)], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("2")]), _c('h5', [_vm._v(_vm._s(_vm.$t("LanguageSpecificInfo")))])]), _c('v-row', {
    staticClass: "box-wrapper",
    staticStyle: {
      "padding-top": "20px"
    }
  }, _vm._l(_vm.languageTypes, function (languageType) {
    return _c('button', {
      key: languageType.name,
      class: {
        'big-box1': !languageType.isChosen,
        'big-box1-white active-box': languageType.isChosen
      },
      attrs: {
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.languageType = languageType.value;
          _vm.chooseBox3(languageType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(languageType.name) + " ")])], 1);
  }), 0), _vm.form.languageType == 'estonian' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "slogan"
    },
    model: {
      value: _vm.form.slogan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "slogan", $$v);
      },
      expression: "form.slogan"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.description,
      expression: "form.description"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "description", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLink", $$v);
      },
      expression: "form.additionalInfoLink"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitle", $$v);
      },
      expression: "form.linkTitle"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "services-in-neighbourhood"
    },
    model: {
      value: _vm.form.servicesInNeighbourhood,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhood", $$v);
      },
      expression: "form.servicesInNeighbourhood"
    }
  })], 1)], 1) : _vm._e(), _vm.form.languageType == 'english' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link",
      "placeholder": _vm.$t('AdditionalInfo')
    },
    model: {
      value: _vm.form.sloganENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganENG", $$v);
      },
      expression: "form.sloganENG"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionENG,
      expression: "form.descriptionENG"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionENG
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionENG", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-eng",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkENG", $$v);
      },
      expression: "form.additionalInfoLinkENG"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-eng",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleENG", $$v);
      },
      expression: "form.linkTitleENG"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "services-in-neighbourhood-eng"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodENG", $$v);
      },
      expression: "form.servicesInNeighbourhoodENG"
    }
  })], 1)], 1) : _vm._e(), _vm.form.languageType == 'russian' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.sloganRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganRU", $$v);
      },
      expression: "form.sloganRU"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionRU,
      expression: "form.descriptionRU"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionRU
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionRU", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkRU", $$v);
      },
      expression: "form.additionalInfoLinkRU"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-ru",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleRU", $$v);
      },
      expression: "form.linkTitleRU"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodRU", $$v);
      },
      expression: "form.servicesInNeighbourhoodRU"
    }
  })], 1)], 1) : _vm._e(), _vm.form.languageType == 'finnish' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.sloganFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganFI", $$v);
      },
      expression: "form.sloganFI"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionFI,
      expression: "form.descriptionFI"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionFI
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionFI", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkFI", $$v);
      },
      expression: "form.additionalInfoLinkFI"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-fi",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleFI", $$v);
      },
      expression: "form.linkTitleFI"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-fi"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodFI,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodFI", $$v);
      },
      expression: "form.servicesInNeighbourhoodFI"
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("6")]), _c('h5', [_vm._v(_vm._s(_vm.$t("Media")))])]), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Pictures")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [!_vm.edit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": [],
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.edit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ApplyWatermarkToImages')
    },
    on: {
      "click": function ($event) {
        return _vm.changeWatermarkStatus($event);
      }
    },
    model: {
      value: _vm.form.isWatermarkApplied,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isWatermarkApplied", $$v);
      },
      expression: "form.isWatermarkApplied"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "video-link",
      "placeholder": _vm.$t('VideoLink')
    },
    model: {
      value: _vm.form.videoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoLink", $$v);
      },
      expression: "form.videoLink"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "Virtual-tour-link",
      "placeholder": _vm.$t('VirtualTourLink')
    },
    model: {
      value: _vm.form.virtualTourLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "virtualTourLink", $$v);
      },
      expression: "form.virtualTourLink"
    }
  })], 1)], 1), _c('div', {
    staticClass: "buttons-row justify-end"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "padding-left": "12px",
      "padding-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('div', {
    staticClass: "add-button",
    attrs: {
      "id": "save-button"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()])])])]), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.form,
      "multi": false
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatus,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }