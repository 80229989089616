<template>
  <div :key="'listing-add-' + updateHTML">
    <div
      class="sticky"
      style="
        display: flex;
        height: 64px;
        width: 100%;
        z-index: 10;
        padding-left: 156px;
        align-items: center;
      "
    >
      <div
        class="add-button"
        @click="saveListing(form, true)"
        style="margin-right: 12px"
      >
        <div
          style="position: relative; width: 30px; height: 30px"
          v-if="isLoading"
          class="loader"
        ></div>
        <img
          v-if="!isLoading"
          style="margin-right: 9px"
          alt=""
          src="../../../../assets/images/checkmarkCircled.svg"
        />
        <p v-if="!isLoading">{{ $t("SaveAndExit") }}</p>
      </div>
      <div
        class="cancel"
        @click="saveListing(form, false)"
        style="margin-right: 12px; cursor: pointer"
      >
        <div
          style="position: relative; width: 30px; height: 30px"
          v-if="isLoading"
          class="loader"
        ></div>
        <p v-if="!isLoading">{{ $t("Save") }}</p>
      </div>

      <button
        @click="$router.back()"
        class="cancel padding-top"
        style="margin-right: 16px"
      >
        {{ $t("Cancel") }}
      </button>
      <div style="margin-right: 16px">•</div>
    </div>
    <div class="header" style="margin-top: 129px; padding-top: 0">
      <h3>{{ $t("EditListing") }}</h3>
      <p
        @click="openStatusDropdown(form)"
        class="statusLabel content-bold"
        id="statusLabel"
        v-bind:class="{
          clickable: edit,
          'draft-label ': form.status === 'draft',
          'active-label ': form.status === 'active',
          'sold-label ': form.status === 'sold',
        }"
      >
        {{ $lfMapper.status(form.status) }}
      </p>
    </div>
    <div class="content">
      <div class="section-row">
        <v-row class="subheading">
          <div class="circle-number" style="padding-top: 1px">1</div>
          <h5>{{ $t("AddProjectAndObjectDetails") }}</h5></v-row
        >

        <v-row class="autocomplete-frame" style="width: 100%">
          <v-row class="deal-name">
            {{ form.projectName }}
          </v-row>
        </v-row>

        <div>
          <v-row class="subheading"
            ><h6>{{ $t("Address") }}</h6></v-row
          >
          <v-row class="address-selected"
            ><p v-if="form.street">{{ form.street }}</p>
            <p v-if="form.houseNumber">&nbsp;</p>
            <p v-if="form.houseNumber" :key="form.houseNumber">
              {{ form.houseNumber }}
            </p>
            <p v-if="form.houseNumber || form.street">,&nbsp;</p>
            <p>{{ form.cityPart }}</p>

            <p v-if="form.cityPart">,&nbsp;</p>
            <p>{{ form.district }}</p>
            <p v-if="form.district">,&nbsp;</p>
            <p>{{ form.city }}</p>
            <p v-if="form.county">,&nbsp;</p>
            <p>{{ form.county }}</p></v-row
          >
          <v-row
            style="
              padding-top: 4px;
              justify-content: space-between;
              width: 100%;
              gap: 12px;
            "
          >
            <v-col class="house-numbers" style="max-width: 50%">
              <v-row v-if="form.addressType !== 'generic'">
                <Dropdown
                  :placeholder-text="$t('County')"
                  :custom-style="'width: 100%;height: 56px;z-index: 3;'"
                  :regular-size="true"
                  :disabled="countiesDisabled"
                  :key="countiesUpdater"
                  v-if="counties"
                  :items="counties"
                  :search-box="true"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedCounty"
                  @itemSelected="selectCounty"
                />
              </v-row>
              <v-row v-if="form.addressType !== 'generic'">
                <Dropdown
                  :custom-style="'width: 100%;font-size: 14px; line-height: 20px; height: 56px;z-index: 2;'"
                  :placeholder-text="$t('City')"
                  :regular-size="true"
                  :disabled="citiesDisabled"
                  :search-box="true"
                  :key="citiesUpdater"
                  :items="cities"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedCity"
                  @itemSelected="selectCity"
                />
              </v-row>
              <v-row v-if="form.addressType !== 'generic'">
                <Dropdown
                  :custom-style="'width: 100%;height: 56px;'"
                  :placeholder-text="$t('District')"
                  :regular-size="true"
                  :disabled="districtsDisabled"
                  :search-box="true"
                  :key="districtsUpdater + 0.2"
                  :items="districts"
                  :display-key="'name'"
                  :value-key="'name'"
                  :selected="selectedDistrict"
                  @itemSelected="selectDistrict"
                />
              </v-row>
              <v-row>
                <BaseInput
                  id="street"
                  v-model="form.street"
                  :placeholder="$t('Street')"
                />
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="house-number"
                  v-model="form.houseNumber"
                  :placeholder="$t('HouseNumber')"
                />
                <v-checkbox
                  v-model="form.showHouseNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>

              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="cadastral-number"
                  v-model="form.cadastralNumber"
                  :placeholder="$t('CadastralNumber')"
                />
                <v-checkbox
                  v-model="form.showCadastralNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="openCadastalLink(form.cadastralNumber)"
                >
                  <img
                    class="link-icon"
                    src="../../../../assets/images/link.svg"
                  />
                  <span style="margin-left: 10px; vertical-align: middle">{{
                    $t("OpenLandRegistry")
                  }}</span>
                </span>
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="estate-number"
                  v-model="form.estateNumber"
                  :placeholder="$t('EstateNumber')"
                  @change="numberInputFormatWholeNumber('estateNumber')"
                  :value-changed="valueChanged"
                />
                <v-checkbox
                  v-model="form.showEstateNumber"
                  :label="$t('Show')"
                  color="black"
                  hide-details
                ></v-checkbox>
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="
                    openELandRegisterLink(
                      form.cadastralNumber,
                      form.estateNumber
                    )
                  "
                >
                  <img
                    class="link-icon"
                    src="../../../../assets/images/link.svg"
                  />
                  <span style="margin-left: 10px; vertical-align: middle">{{
                    $t("OpenELandRegister")
                  }}</span>
                </span>
              </v-row>
              <v-row style="gap: 24px; align-items: center; flex-wrap: nowrap">
                <BaseInput
                  id="ehr-number"
                  v-model="form.ehrNumber"
                  :placeholder="$t('EhrNumber')"
                  @change="numberInputFormatWholeNumber('ehrNumber')"
                  :value-changed="valueChanged"
                />
              </v-row>
              <v-row style="margin-left: 2px; padding: 8px 0 8px 0">
                <span
                  class="link-small"
                  @click="openEhrNumberLink(form.ehrNumber)"
                >
                  <img
                    class="link-icon"
                    src="../../../../assets/images/link.svg"
                  />
                  <span style="margin-left: 10px; vertical-align: middle"
                    >{{ $t("Visit") }} EHR</span
                  >
                </span>
              </v-row>
            </v-col>
            <v-col>
              <div id="map">
                <GmapMap
                  :center="mapCoordinates"
                  :zoom="18"
                  map-type-id="terrain"
                  @click="setMarker"
                  style="width: 432px; height: 240px"
                >
                  <GmapMarker
                    id="marker"
                    :position="markerCoordinates"
                    :clickable="true"
                    :draggable="true"
                    @drag="updateMarker"
                  />
                </GmapMap>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="section">
        <v-row>
          <div class="circle-number">2</div>
          <h5>{{ $t("LanguageSpecificInfo") }}</h5>
        </v-row>
        <v-row class="box-wrapper" style="padding-top: 20px">
          <button
            v-for="languageType in languageTypes"
            :key="languageType.name"
            item-text="name"
            item-value="value"
            @click="
              form.languageType = languageType.value;
              chooseBox3(languageType.value);
            "
            v-bind:class="{
              'big-box1': !languageType.isChosen,
              'big-box1-white active-box': languageType.isChosen,
            }"
          >
            <v-row style="align-items: center">
              {{ languageType.name }}
            </v-row>
          </button>
        </v-row>
        <v-row
          style="margin-top: 0px"
          v-if="
            form.languageType == 'estonian'
          "
        >
          <v-row style="margin-top: 32px">
            <h6>{{ $t("Slogan") }}</h6>
          </v-row>
          <v-row style="margin-top: 16px; width: 100%">
            <BaseInput
              id="slogan"
              v-model="form.slogan"
              style="max-width: unset"
            />
          </v-row>
          <v-row class="subheading">
            <h6>{{ $t("AdditionalInfo") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <textarea v-model="form.description" id="textarea" name="textarea">
            </textarea>
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              id="additional-info-link"
              v-model="form.additionalInfoLink"
              :placeholder="$t('AdditionalInfoLink')"
              style="width: 444px; max-width: unset"
            />
            <BaseInput
              id="link-title"
              v-model="form.linkTitle"
              :placeholder="$t('LinkTitle')"
              style="width: 444px; max-width: unset"
            />
          </v-row>
          <v-row style="margin-top: 0px; padding-top: 32px !important">
            <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <BaseInput
              id="services-in-neighbourhood"
              v-model="form.servicesInNeighbourhood"
              style="max-width: unset"
            />
          </v-row>
        </v-row>

        <v-row style="margin-top: 0px" v-if="form.languageType == 'english'">
          <v-row style="margin-top: 32px">
            <h6>{{ $t("Slogan") }}</h6>
          </v-row>
          <v-row style="margin-top: 16px; width: 100%">
            <BaseInput
              id="additional-info-link"
              v-model="form.sloganENG"
              :placeholder="$t('AdditionalInfo')"
              style="max-width: unset"
            />
          </v-row>
          <v-row class="subheading">
            <h6>{{ $t("AdditionalInfo") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <textarea
              v-model="form.descriptionENG"
              id="textarea"
              name="textarea"
            >
            </textarea>
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              id="additional-info-link-eng"
              v-model="form.additionalInfoLinkENG"
              :placeholder="$t('AdditionalInfoLink')"
              style="width: 444px; max-width: unset"
            />
            <BaseInput
              id="link-title-eng"
              v-model="form.linkTitleENG"
              :placeholder="$t('LinkTitle')"
              style="width: 444px; max-width: unset"
            />
          </v-row>
          <v-row style="margin-top: 0px; padding-top: 32px !important">
            <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <BaseInput
              id="services-in-neighbourhood-eng"
              v-model="form.servicesInNeighbourhoodENG"
              style="max-width: unset"
            />
          </v-row>
        </v-row>
        <v-row style="margin-top: 0px" v-if="form.languageType == 'russian'">
          <v-row style="margin-top: 32px">
            <h6>{{ $t("Slogan") }}</h6>
          </v-row>
          <v-row style="margin-top: 16px; width: 100%">
            <BaseInput
              id="additional-info-ru"
              v-model="form.sloganRU"
              style="max-width: unset"
            />
          </v-row>
          <v-row class="subheading">
            <h6>{{ $t("AdditionalInfo") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <textarea
              v-model="form.descriptionRU"
              id="textarea"
              name="textarea"
            >
            </textarea>
            <!--              <BaseTextarea-->
            <!--                  id="additional-info-ru"-->
            <!--                  v-model="form.descriptionRU"-->
            <!--                  style="max-width: unset"-->
            <!--              />-->
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              id="additional-info-link-ru"
              v-model="form.additionalInfoLinkRU"
              :placeholder="$t('AdditionalInfoLink')"
              style="width: 444px; max-width: unset"
            />
            <BaseInput
              id="link-title-ru"
              v-model="form.linkTitleRU"
              :placeholder="$t('LinkTitle')"
              style="width: 444px; max-width: unset"
            />
          </v-row>
          <v-row style="margin-top: 0px; padding-top: 32px !important">
            <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <BaseInput
              id="additional-info-ru"
              v-model="form.servicesInNeighbourhoodRU"
              style="max-width: unset"
            />
          </v-row>
        </v-row>
        <v-row style="margin-top: 0px" v-if="form.languageType == 'finnish'">
          <v-row style="margin-top: 32px">
            <h6>{{ $t("Slogan") }}</h6>
          </v-row>
          <v-row style="margin-top: 16px; width: 100%">
            <BaseInput
              id="additional-info-ru"
              v-model="form.sloganFI"
              style="max-width: unset"
            />
          </v-row>
          <v-row class="subheading">
            <h6>{{ $t("AdditionalInfo") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <textarea
              v-model="form.descriptionFI"
              id="textarea"
              name="textarea"
            >
            </textarea>
            <!--              <BaseTextarea-->
            <!--                  id="additional-info-ru"-->
            <!--                  v-model="form.descriptionRU"-->
            <!--                  style="max-width: unset"-->
            <!--              />-->
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              id="additional-info-link-ru"
              v-model="form.additionalInfoLinkFI"
              :placeholder="$t('AdditionalInfoLink')"
              style="width: 444px; max-width: unset"
            />
            <BaseInput
              id="link-title-fi"
              v-model="form.linkTitleFI"
              :placeholder="$t('LinkTitle')"
              style="width: 444px; max-width: unset"
            />
          </v-row>
          <v-row style="margin-top: 0px; padding-top: 32px !important">
            <h6>{{ $t("ServicesInNeighbourhood") }}</h6>
          </v-row>
          <v-row style="padding-top: 4px; width: 100%">
            <BaseInput
              id="additional-info-fi"
              v-model="form.servicesInNeighbourhoodFI"
              style="max-width: unset"
            />
          </v-row>
        </v-row>
      </div>
      <div class="section">
        <v-row>
          <div class="circle-number">6</div>
          <h5>{{ $t("Media") }}</h5></v-row
        >
        <v-row class="subheading">
          <h6>{{ $t("Pictures") }}</h6></v-row
        >
        <v-row style="padding-top: 4px; width: 100%">
          <UploadImages
            v-if="!edit"
            :Imgs="[]"
            :is-visible="true"
            @changed="putImages($event)"
            class="image-uploader"
            :clearAll="$t('ClearAll')"
          />
          <UploadImages
            v-if="edit"
            :Imgs2="form.images"
            @changed="putImages($event)"
            :is-visible="true"
            class="image-uploader"
            :clearAll="$t('ClearAll')"
          />
        </v-row>
        <v-row style="width: 100%">
          <BaseToggle
            v-model="form.isWatermarkApplied"
            @click="changeWatermarkStatus($event)"
            :label="$t('ApplyWatermarkToImages')"
          />
        </v-row>
        <v-row style="width: 100%">
          <BaseInput
            id="video-link"
            v-model="form.videoLink"
            :placeholder="$t('VideoLink')"
            style="max-width: unset; width: 100%"
          />
        </v-row>
        <v-row style="width: 100%">
          <BaseInput
            id="Virtual-tour-link"
            v-model="form.virtualTourLink"
            :placeholder="$t('VirtualTourLink')"
            style="max-width: unset; width: 100%"
          />
        </v-row>
      </div>
      <div class="buttons-row justify-end">
        <div
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 12px;
          "
        >
          <button
            @click="$router.back()"
            class="cancel padding-top"
            style="padding-left: 12px; padding-right: 12px"
          >
            {{ $t("Cancel") }}
          </button>
          <div
            class="cancel"
            @click="saveListing(form, false)"
            style="margin-right: 12px; cursor: pointer"
          >
            <div
              style="position: relative; width: 30px; height: 30px"
              v-if="isLoading"
              class="loader"
            ></div>
            <p v-if="!isLoading">{{ $t("Save") }}</p>
          </div>
          <div
            id="save-button"
            class="add-button"
            @click="saveListing(form, true)"
          >
            <div
              style="position: relative; width: 30px; height: 30px"
              v-if="isLoading"
              class="loader"
            ></div>
            <img
              v-if="!isLoading"
              style="margin-right: 9px"
              alt=""
              src="../../../../assets/images/checkmarkCircled.svg"
            />
            <p v-if="!isLoading">{{ $t("SaveAndExit") }}</p>
          </div>
        </div>
      </div>
    </div>
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeListingStatus"
      @open-booking-modal="openBookingDetailsModal"
      v-if="statusesOpen"
      :position="position"
      :listing="form"
      :multi="false"
    />
  </div>
</template>

<script>
import axios from "axios";
import ClickOutside from "vue-click-outside";
import nanoid from "nanoid";
import { mapActions, mapGetters } from "vuex";
import Dropdown from "@/components/common/Dropdown";
import BaseInput from "@/components/common/BaseInput";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import UploadImages from "@/components/common/vue-upload-drop-images";
import BaseToggle from "@/components/common/BaseToggle";
const locationsEstonia = require("../../../../components/common/locations_estonia2");

export default {
  name: "ListingsAdd",
  components: {
    Dropdown,
    BaseInput,
    StatusDropdown,
    UploadImages,
    BaseToggle,
  },
  directives: {
    ClickOutside,
  },
  props: ["project"],
  watch: {
    landAreaUnit(value) {
      console.log("LANDAREA UNIT CHANGED", value);
      // if form.landArea.ha or m2 exists then convert values here
      const reg = new RegExp("\\d*\\.?\\d*");
      if (this.form.landArea.m2 && !this.form.landArea.ha && value === "ha") {
        if (
          reg.test(this.form.landArea.m2) &&
          this.isValidNumber(this.form.landArea.m2)
        ) {
          this.form.landArea.ha = (this.form.landArea.m2 * 0.0001).toFixed(2);
        }
      } else if (
        this.form.landArea.ha &&
        !this.form.landArea.m2 &&
        value === "m2"
      ) {
        if (
          reg.test(this.form.landArea.ha) &&
          this.isValidNumber(this.form.landArea.ha)
        ) {
          this.form.landArea.m2 = (this.form.landArea.ha * 10000).toFixed(2);
        }
      }
      //
      this.activePriceCalculatingInput = value;
      this.priceCalculate();
    },
    rightEstateType: function () {
      this.updateHTML++;
    },
    object() {
      if (this.object && this.object.address) {
        this.aadressSearch.setAddress(this.object.address);
        this.activatePlaceholder(true);
      }
    },
    objectSelectionOpen() {},
    activeView: function (val) {
      this.$emit("viewChanged", val);
    },
    deals: function () {
      this.dealsLoaded = true;
    },
    dealsLoaded: function () {
      this.closeMenu();
    },
    "form.addressType": function (newValue) {
      if (newValue === "generic") {
        this.form.marketplaces.forEach((marketplace) => {
          if (
            marketplace.name === "kv" ||
            marketplace.name === "kv24" ||
            marketplace.name === "city24"
          ) {
            marketplace.isActive = false;
          }
        });
      }
    },
    "form.availableImmediately": function () {
      if (this.form.availableImmediately) {
        this.form.dateAvailable = null;
      }
    },

    form: {
      handler() {
        if (this.inAddress !== null) {
          this.isErrorAddress = false;
        }

        if (this.form.houseNumber !== null) {
          this.isErrorHouseNr = false;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      bookingDetailsModalOpen: false,
      bookingUpdater: 0,
      bookedListing: null,
      valueChanged: 0,
      activePriceCalculatingInput: "",
      showErrors: null,
      errors: false,
      rightEstateType: null,
      updateHTML: 0,
      addressSelected: false,
      landAreaUnit: "m2",
      isShortTermRent: false,
      deleteModalOpen: false,
      deletePermanentlyModalOpen: false,
      addToProject: false,
      edit: false,
      deal: null,
      selectedCounty: null,
      selectedCity: null,
      selectedDistrict: null,
      county: null,
      city: null,
      cityPart: null,
      countiesDisabled: true,
      citiesDisabled: true,
      districtsDisabled: true,
      countiesUpdater: 0,
      citiesUpdater: 0,
      districtsUpdater: 0,
      projectAddOpen: false,
      objectSelectionOpen: false,
      position: {},
      activeMarketplaces: {},
      closeDealMenu: 0,
      dealsLoaded: false,
      squareMeterPriceKey: 0,
      priceKey: 0,
      chosenLinkedDeal: null,
      chosenLinkedDealName: "",
      mapCoordinates: { lat: 59.437109, lng: 24.743749 },
      markerCoordinates: { lat: 59.437109, lng: 24.743749 },
      dealTypes: [
        { name: this.$t("Sale"), isChosen: true, value: "sale" },
        { name: this.$t("Rent"), isChosen: false, value: "rent" },
        {
          name: this.$t("ShortTermRent"),
          isChosen: false,
          value: "short_term_rent",
        },
      ],
      estateTypes: [
        { name: this.$t("Apartment"), isChosen: true, value: "apartment" },
        { name: this.$t("House"), isChosen: false, value: "house" },
        { name: this.$t("Land"), isChosen: false, value: "land" },
        {
          name: this.$t("Commercial"),
          isChosen: false,
          value: "commercial",
        },
        { name: this.$t("Maisonette"), isChosen: false, value: "house_part" },
        { name: this.$t("Cottage"), isChosen: false, value: "cottage" },
        { name: this.$t("Garage"), isChosen: false, value: "garage" },
      ],
      sectionTypes: [
        { name: this.$t("Extras"), isChosen: true, value: "extras" },
        {
          name: this.$t("SanitaryArrangements"),
          isChosen: false,
          value: "sanitary",
        },
        {
          name: this.$t("Surroundings"),
          isChosen: false,
          value: "surroundings",
        },
        {
          name: this.$t("Communications&Security"),
          isChosen: false,
          value: "communication",
        },
        {
          name: this.$t("HeatingandVentilation"),
          isChosen: false,
          value: "heating",
        },
        { name: this.$t("Kitchen"), isChosen: false, value: "kitchen" },
      ],
      languageTypes: [
        {
          name: this.$t("EstonianLanguage"),
          isChosen: true,
          value: "estonian",
        },
        { name: this.$t("EnglishLanguage"), isChosen: false, value: "english" },
        { name: this.$t("RussianLanguage"), isChosen: false, value: "russian" },
        { name: this.$t("FinnishLanguage"), isChosen: false, value: "finnish" },
      ],
      counties: [{ name: this.$t("County"), value: "county", _id: nanoid(16) }],
      conditions: [
        { name: this.$t("NewBuilding"), value: "ALACRITY_NEW_BUILDING" },
        {
          name: this.$t("NewlyDecorated"),
          value: "ALACRITY_TYPE_UUSVIIMISTLUS",
        },
        { name: this.$t("Renovated"), value: "ALACRITY_RENEWED" },
        { name: this.$t("EssentialRepairDone"), value: "ALACRITY_SAN" },
        { name: this.$t("Satisfactory"), value: "ALACRITY_KESKMINE" },
        { name: this.$t("NeedsCompleteRenewal"), value: "ALACRITY_TYPE_KAP" },
        { name: this.$t("NeedsDecoration"), value: "ALACRITY_TYPE_REM" },
        { name: this.$t("Ready"), value: "ALACRITY_READY" },
      ],
      buildingTypes: [
        { name: this.$t("House"), value: "BUILDING_TYPE_HOUSE" },
        { name: this.$t("RowHouseBox"), value: "BUILDING_TYPE_HOUSEBOX" },
        { name: this.$t("HouseShare"), value: "BUILDING_TYPE_HOUSESHARE" },
        { name: this.$t("Cottage"), value: "BUILDING_TYPE_COTTAGE" },
        { name: this.$t("FarmHouse"), value: "BUILDING_TYPE_FARMHOUSE" },
        { name: this.$t("Other"), value: "BUILDING_TYPE_OTHER" },
      ],
      buildingMaterials: [
        { name: this.$t("StoneHouse"), value: "BUILDING_MATERIAL_TYPE_KIVI" },
        { name: this.$t("LogHouse"), value: "BUILDING_MATERIAL_TYPE_PALK" },
        { name: this.$t("PanelHouse"), value: "BUILDING_MATERIAL_TYPE_PANEEL" },
        { name: this.$t("WoodenHouse"), value: "BUILDING_MATERIAL_TYPE_PUU" },
      ],
      energyLabels: [
        { name: "A", value: "ENERGY_CLASS_A" },
        { name: "B", value: "ENERGY_CLASS_B" },
        { name: "C", value: "ENERGY_CLASS_C" },
        { name: "D", value: "ENERGY_CLASS_D" },
        { name: "E", value: "ENERGY_CLASS_E" },
        { name: "F", value: "ENERGY_CLASS_F" },
        { name: "G", value: "ENERGY_CLASS_G" },
        { name: "H", value: "ENERGY_CLASS_H" },
        { name: this.$t("NoCertificate"), value: "no_certificate" },
      ],
      parkingOptions: [
        { name: this.$t("ForFee"), value: "PARKING_FEE" },
        { name: this.$t("FreeParking"), value: "PARKING_FREE" },
        { name: this.$t("NoParking"), value: "NO_PARKING" },
      ],
      roofTypes: [
        { name: this.$t("Bitumen"), value: "ROOF_TYPE_BITUMIN" },
        { name: this.$t("Eternit"), value: "ROOF_TYPE_ETERNIIT" },
        { name: this.$t("Stone"), value: "ROOF_TYPE_KIVI" },
        { name: this.$t("Slate"), value: "ROOF_TYPE_SLATE" },
        { name: this.$t("Tiling"), value: "ROOF_TYPE_TILING" },
        { name: this.$t("TinSheet"), value: "ROOF_TYPE_PLEKK" },
        { name: this.$t("PVC"), value: "ROOF_TYPE_PVC" },
        { name: this.$t("RolledMaterial"), value: "ROOF_TYPE_RULL" },
        { name: this.$t("ZincSheet"), value: "ROOF_TYPE_TSINKPLEKK" },
      ],
      detailedPlans: [
        { name: this.$t("Exist"), value: "DETAILED_PLAN_EXIST" },
        { name: this.$t("None"), value: "DETAILED_PLAN_NONE" },
        { name: this.$t("Started"), value: "DETAILED_PLAN_STARTED" },
      ],
      /*  stoves: [
        { name: this.$t("ElectricalStove"), value: "STOVE_ELECTRIC" },
        { name: this.$t("GasStove"), value: "STOVE_GAS" },
        { name: this.$t("CeramicStove"), value: "STOVE_CERAMIC" },
        { name: this.$t("FireWoodStove"), value: "STOVE_WOOD" },
        { name: this.$t("InductionHob"), value: "STOVE_INDUCTION" },
      ], */
      readinesses: [
        { name: this.$t("Ready"), value: "READINESS_READY" },
        {
          name: this.$t("BoxWithDoorsAndWindows"),
          value: "READINESS_BOX_WITH_DOORS",
        },
        { name: this.$t("RoofedBox"), value: "READINESS_ROOFED_BOX" },
        { name: this.$t("RooflessBox"), value: "READINESS_ROOFLESS_BOX" },
        { name: this.$t("Foundation"), value: "READINESS_FOUNDATION" },
      ],
      limitations: [
        { name: this.$t("InMortage"), value: "CONSTRAINTS_MORTGAGE" },
        { name: this.$t("OtherLimitations"), value: "CONSTRAINTS_OTHER" },
        { name: this.$t("Pledge"), value: "CONSTRAINTS_PANT" },
        { name: this.$t("PreservedTerritory"), value: "CONSTRAINTS_PROTECTED" },
        { name: this.$t("RentAgreement"), value: "CONSTRAINTS_RENT" },
        { name: this.$t("UnderSeizure"), value: "CONSTRAINTS_DISTRESS" },
      ],
      securitySystems: [
        { name: this.$t("CablingDone"), value: "SECURITY_SYSTEM_CABLING" },
        { name: this.$t("Installed"), value: "SECURITY_SYSTEM_INSTALLED" },
      ],
      reservois: [
        { name: this.$t("Sea"), value: "WATERBODY_SEA" },
        { name: this.$t("Lake"), value: "WATERBODY_LAKE" },
        { name: this.$t("River"), value: "WATERBODY_RIVER" },
        { name: this.$t("OtherWaterBody"), value: "WATERBODY_OTHER" },
      ],
      neighbours: [
        { name: this.$t("Around"), value: "AROUND" },
        { name: this.$t("NextTo"), value: "NEXT_TO" },
        { name: this.$t("AtOneSide"), value: "AT_ONE_SIDE" },
        { name: this.$t("Farther"), value: "FARTHER" },
      ],
      roads: [
        { name: this.$t("InGoodCondition"), value: "ROADS_GOOD_CONDITION" },
        {
          name: this.$t("InSatisfactoryCondition"),
          value: "ROADS_SATISFACTORY_CONDITION",
        },
        { name: this.$t("InBadCondition"), value: "ROADS_BAD_CONDITION" },
        { name: this.$t("Paved"), value: "ROADS_PAVED" },
        { name: this.$t("GravelRoad"), value: "ROADS_GRAVEL" },
      ],
      locations: [
        { name: this.$t("InCentre"), value: "LOCATION_CENTRE" },
        { name: this.$t("InSuburb"), value: "LOCATION_SUBURB" },
        { name: this.$t("OutsideTheSettlement"), value: "LOCATION_OUTSIDE" },
        { name: this.$t("InTheCity"), value: "LOCATION_CITY" },
      ],
      buildings: [
        { name: this.$t("PrivateHouses"), value: "BUILDING_PRIVATE_HOUSE" },
        {
          name: this.$t("PrivateHousesOrApartments"),
          value: "BUILDING_PRIVATE_HOUSEAPART",
        },
        { name: this.$t("ApartmentBuildings"), value: "BUILDING_APARTMENT" },
        { name: this.$t("CommercialBuildings"), value: "BUILDING_COMMERCIAL" },
        {
          name: this.$t("ManufacturingBuildings"),
          value: "BUILDING_MANUFACTURE",
        },
        { name: this.$t("NoBuildings"), value: "BUILDING_NO" },
      ],
      ownershipForms: [
        { name: this.$t("ApartmentOwnership"), value: "PROPERTY_KORTERIOMAND" },
        { name: this.$t("CommonOwnership"), value: "PROPERTY_TYPES" },
        { name: this.$t("CooperativeHousing"), value: "PROPERTY_ELAMUYHISTU" },
        { name: this.$t("ImmovableProperty"), value: "PROPERTY_KINNISTU" },
        { name: this.$t("MovableProperty"), value: "PROPERTY_VALLASASI" },
        { name: this.$t("RightOfSuperficies"), value: "PROPERTY_BUILDING" },
        { name: this.$t("Municipal"), value: "PROPERTY_MUNICIPAL" },
      ],
      image: null,
      deals: null,
      relatedProject: null,
      isLoading: false,
      search: null,
      isErrorDeal: false,
      isErrorStreet: false,
      isErrorHouseNr: false,
      isErrorAddress: false,
      statusesOpen: false,
      form: {
        foreignCountry: false,
        addressType: "estonia",
        realAddress: {},
        status: "draft",
        linkedDeal: null,
        linkedDeals: [],
        dealType: "sale",
        estateType: "apartment",
        object: null,
        county: null,
        city: null,
        district: null,
        cityPart: null,
        coordinates: null,
        street: "",
        ehakCode: null,
        houseNumber: null,
        solidFuel: false,
        geothermalHeating: false,
        apartmentNumber: null,
        landPurpose: null,
        bathroomCount: null,
        showHouseNumber: true,
        showApartmentNumber: false,
        liquidFuel: false,
        centralHeating: false,
        cadastralNumber: null,
        airHeatPump: false,
        landArea: {
          m2: null,
          m2Selected: true,
          ha: null,
          haSelected: false,
        },
        isWatermarkApplied: true,
        estateNumber: null,
        windowType: null,
        brokerFee: null,
        buildingType: null,
        floorHeating: false,
        cadastralSize: null,
        roofType: null,
        centralGas: false,
        buildingStatus: null,
        localSewerage: false,
        centralSewerage: false,
        buildingPermit: false,
        heatingType: null,
        separateEntrance: false,
        buildYear: null,
        floors: null,
        gasHeating: false,
        floor: null,
        rooms: null,
        pricePerSquareMeter: null,
        bedrooms: null,
        trestle: false,
        goodsLift: false,
        generalSurfaceArea: null,
        furnaceHeating: false,
        price: "0",
        condition: null,
        ownershipForm: null,
        buildingMaterial: null,
        electricalHeating: false,
        detailPlan: false,
        sloganRU: null,
        sloganENG: null,
        sloganFI: null,
        descriptionRU: null,
        descriptionENG: null,
        descriptionFI: null,
        possibilityToChangeType: false,
        airWaterPump: false,
        energyLabel: null,
        expensesInSummer: null,
        expensesInWinter: null,
        pricePerDay: null,
        possibilityToChangePurpose: false,
        listingOwnerName: null,
        listingOwnerEmail: null,
        listingOwnerPhone: null,
        vatIncluded: false,
        depositPrice: null,
        balconyType: null,
        virtualTourLink: null,
        videoLink: null,
        sewer: null,
        freeParking: false,
        paidParking: false,
        balconySize: null,
        kitchenSize: null,
        garageType: null,
        garage: false,
        undergroundGarage: false,
        purpose: null,
        entranceType: null,
        parkingPlaces: null,
        rentIncluded: false,
        electricityPaid: false,
        subscriptionPaid: false,
        otherPrice: null,
        waterIncluded: false,
        summerPrice: null,
        gasIncluded: false,
        winterPrice: null,
        airheatPumpIncluded: false,
        industrialElectricity: false,
        parkingPossibility: false,
        electricity: false,
        marketplaces: [
          {
            name: "kv",
            isActive: true,
          },
          {
            name: "city24",
            isActive: true,
          },
          {
            name: "kv24",
            isActive: true,
          },
          {
            name: "www",
            isActive: true,
          },
        ],
        ancillaryBuilding: false,
        lift: false,
        parquet: false,
        packetWindows: false,
        ventilation: false,
        climaticDevice: false,
        forestIsNear: false,
        transportationIsNear: false,
        furniture: false,
        furnitureAbility: false,
        roomsSeparate: false,
        parkingOption: null,
        terrace: false,
        tv: false,
        basementFloor: false,
        water: false,
        closedYard: false,
        highCeilings: false,
        severalFloorsPlanning: false,
        cupBoard: false,
        showCadastralNumber: true,
        showEstateNumber: false,
        availableImmediately: true,
        dateAvailable: null,
        livingArea: null,
        readiness: null,
        limitations: null,
        detailedPlan: null,
        commercialLand: false,
        industrialLand: false,
        productionLand: false,
        forestLand: false,
        residentialLand: false,
        profityieldingLand: false,
        residentialLandApartment: false,
        otherUse: false,
        office: false,
        accomodation: false,
        tradeshop: false,
        services: false,
        warehouse: false,
        production: false,
        foodServices: false,
        notDefined: false,
        warehouseProduction: false,
        otherUsePossible: false,
        rentToOwn: false,
        additionalInfoLink: null,
        additionalInfoLinkENG: null,
        additionalInfoLinkRU: null,
        additionalInfoLinkFI: null,
        linkTitle: null,
        linkTitleENG: null,
        linkTitleRU: null,
        linkTitleFI: null,
        servicesInNeighbourhood: null,
        servicesInNeighbourhoodENG: null,
        servicesInNeighbourhoodRU: null,
        servicesInNeighbourhoodFI: null,
        balcony: false,
        closedBalcony: false,
        fullyGlazedBalcony: false,
        frenchBalcony: false,
        numberOfDesks: null,
        wardrobe: false,
        showCaseWindows: false,
        streetEntrance: false,
        rooftopApartment: false,
        industrialPowerSupply: false,
        well: false,
        sewerage: false,
        road: false,
        publicTransport: false,
        localWaterSupply: false,
        centralWaterSupply: false,
        coastLineLength: null,
        kitchen: false,
        electricalWiring: false,
        storeRoom: false,
        combinedHeating: false,
        attic: false,
        petsAllowed: false,
        meetingRoom: false,
        desks: false,
        shopWindow: false,
        pool: false,
        openedKitchen: false,
        fridge: false,
        fence: false,
        kitchenFurniture: false,
        washingMachine: false,
        broiler: false,
        boiler: false,
        shower: false,
        bath: false,
        sauna: false,
        wcBath: false,
        piping: false,
        firePlace: false,
        conditioner: false,
        internet: false,
        cableTv: false,
        telephone: false,
        securityDoor: false,
        staircaseLocked: false,
        neighbourhoodSecurity: false,
        guard: false,
        videoSecurity: false,
        reservoirName: null,
        reservoirDistance: null,
        gullyHole: null,
        stove: null,
        electricalStove: false,
        gasStove: false,
        ceramicStove: false,
        fireWoodStove: false,
        inductionStove: false,
        securitySystem: null,
        reservoir: null,
        neighbours: null,
        roads: null,
        location: null,
        building: null,
        amBroker: 1,
        additionalInfo: {
          reserved: false,
          tradable: false,
          doNotAllowToCopyListing: false,
          brokersNotAllowedToDisturb: false,
        },
        slogan: null,
        description: "",
        images: [],
      },
      inAadress: null,
      //
      addressSearchContainer: null,
      labelElement: null,
      inputElement: null,
      dropdownContainer: null,
      clearButton: null,
      //
    };
  },
  async created() {
    this.$forceUpdate();
    locationsEstonia.locations.counties.forEach((location) => {
      this.counties.push({ name: location.NIMETUS, value: location.KOOD });
    });

    if (this.$route.params.id) {
      this.edit = true;
      await this.getDevelopment();
    }
  },
  mounted() {},

  computed: {
    ...mapGetters([
      "sidepanelDealsAdd",
      "user",
      "navigationAfterAddingProject",
    ]),

    cities() {
      let cities = null;
      if (this.county) {
        let citiesTemp = [
          { name: this.$t("City"), value: "city", _id: nanoid(16) },
        ];
        locationsEstonia.locations.cities.forEach((location) => {
          if (location.YLEMKOMP_KOOD === this.county.value) {
            let name = location.NIMETUS;
            let value = location.KOOD;
            citiesTemp.push({ name, value });
          }
        });
        cities = citiesTemp;
        this.enableCities();
      } else {
        cities = [{ name: this.$t("City"), value: "city", _id: nanoid(16) }];
        this.disableCities();
        this.disabledistricts();
      }
      return cities;
    },
    districts() {
      let districts = null;
      if (this.city) {
        let districtsTemp = [
          { name: this.$t("District"), value: "district", _id: nanoid(16) },
        ];
        locationsEstonia.locations.districts.forEach((location) => {
          if (location.YLEMKOMP_KOOD === this.city.value) {
            let name = location.NIMETUS;
            let value = location.KOOD;
            districtsTemp.push({ name, value });
          }
        });
        districts = districtsTemp;
        if (districts.length > 1) {
          this.enabledistricts();
        }
      } else {
        districts = [{ name: this.$t("District"), value: "district" }];
        this.disabledistricts();
      }
      return districts;
    },
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDeal",
      "setNavigationAfterAddingProject",
    ]),
    chooseBox3(choice) {
      this.languageTypes.forEach((type) => {
        if (type.value == choice) {
          type.isChosen = true;
        } else {
          type.isChosen = false;
        }
      });
      this.updateHTML++;
    },
    openBookingDetailsModal(listing) {
      this.bookedListing = listing;
      this.bookingDetailsModalOpen = true;
    },
    changeListingStatus(event) {
      this.statusesOpen = false;
      this.form.status = event.status;
      this.form.bookedUntil = event.bookedUntil;
      this.form.activeUntil = event.activeUntil;
      this.form.statusChangedDate = event.statusChangedDate;
      this.bookingUpdater++;
    },
    openStatusDropdown() {
      if (this.edit) {
        let button = document.getElementById("statusLabel");
        const rect = button.getBoundingClientRect();
        this.position.right = window.innerWidth - rect.right - 50;
        this.position.top = rect.top + window.scrollY - 50;
        this.statusesOpen = true;
      }
    },
    isValidNumber(value) {
      return !(isNaN(value) || value === Infinity || value === "Infinity");
    },
    async changeWatermarkStatus(status) {
      this.form.isWatermarkApplied = status ? true : false;
    },
    formatNumberInput(value) {
      let outputValue = String(value);
      var regExp = /[a-zA-Z]/g;
      // if value has , replace with --> .
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join(".");
      }
      // if value has . or any letter --> delete last converted . or letter
      if (outputValue.split(".").length > 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      this.valueChanged++;
      console.log("OUTPUT VALUE", outputValue);

      return outputValue;
    },
    formatNumbersOnlyInput(value) {
      let outputValue = String(value);
      var regExp = /[a-zA-Z]/g;
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join("");
      }
      if (outputValue.split(".").length >= 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      this.valueChanged++;
      return outputValue;
    },
    numberInputFormatWholeNumber(input) {
      this.form[input] = this.formatNumbersOnlyInput(this.form[input]);
    },
    openCadastalLink(cadastralNumber) {
      if (cadastralNumber) {
        window.open(`https://xgis.maaamet.ee/ky/${cadastralNumber}`, "_blank");
      } else {
        window.open(`https://xgis.maaamet.ee/ky/`, "_blank");
      }
    },
    openEhrNumberLink(ehrNumber) {
      if (ehrNumber) {
        window.open(
          `https://livekluster.ehr.ee/ui/ehr/v1/building/${ehrNumber}`,
          "_blank"
        );
      } else {
        window.open(
          "https://livekluster.ehr.ee/ui/ehr/v1/detailsearch/BUILDINGS_SEARCH",
          "_blank"
        );
      }
    },
    openELandRegisterLink(cadastralNumber, estateNumber) {
      if (!estateNumber && cadastralNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?lang=Est&kadasterAadress=&nimi=&regNrIsikuKood=${cadastralNumber}`,
          "_blank"
        );
      } else if (estateNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?kadasterAadress=&nimi=&regNrIsikuKood=${estateNumber}`,
          "_blank"
        );
      } else if (!estateNumber && !cadastralNumber) {
        window.open("https://kinnistusraamat.rik.ee/Avaleht.aspx", "_blank");
      }
    },

    focusInput() {
      this.activatePlaceholder();
    },
    async unfocusInput() {
      this.deactivatePlaceholder();
    },
    activatePlaceholder() {
      this.labelElement.style.fontSize = "11px";
      this.labelElement.style.height = "12px";
      this.labelElement.style.color = "#FF5C01";
      this.labelElement.style.lineHeight = "11px";
      //input
      this.inputElement.style.height = "24px";
      this.inputElement.focus();
      document.addEventListener("click", this.checkIfDropdownClicked);
    },
    deactivatePlaceholder() {
      this.labelElement.style.color = "#75787a";
      if (!this.inputElement.value) {
        this.setupInput();
        this.setupLabel();
      }
    },
    checkIfDropdownClicked(e) {
      if (this.isDropdownOpen()) {
        const paths = e.path || (e.composedPath && e.composedPath());
        paths.forEach((path) => {
          if (path.classList && path.classList.contains("inads-result")) {
            // not hidden
          } else {
            this.dropdownContainer.classList.add("hidden");
          }
        });
      }
    },
    isDropdownOpen() {
      return !this.dropdownContainer.classList.contains("hidden");
    },
    setupAddressSearchContainer() {
      this.addressSearchContainer.style.display = "flex";
      this.addressSearchContainer.style.flexDirection = "column";
      this.addressSearchContainer.style.justifyContent = "center";
    },
    setupLabel() {
      this.labelElement.innerHTML = this.$t("FindAddress");
      this.labelElement.style.fontSize = "14px";
      this.labelElement.style.transition = "0.2s";
      this.labelElement.style.color = "#75787a";
      this.labelElement.style.width = "calc(100% - 36px)";
      this.addressSearchContainer.insertBefore(
        this.labelElement,
        this.addressSearchContainer.firstChild
      );
    },
    setupInput() {
      this.inputElement.style.height = "0";
      this.inputElement.style.transition = "0.2s";
      this.inputElement.style.backgroundColor = "";
      this.inputElement.placeholder = "";
      this.inputElement.style.width = "calc(100% - 36px)";
    },
    setupDropdownContainer() {
      this.dropdownContainer.style.top = "0";
      this.dropdownContainer.style.width = "472px";
      this.dropdownContainer.style.margin = "0 auto";
      this.dropdownContainer.style.border = "none";
      this.dropdownContainer.style.boxShadow =
        "0 4px 8px rgb(0 0 0 / 4%), 0 1px 2px rgb(0 0 0 / 3%), 0 0 1px rgb(0 0 0 / 4%)";
      this.dropdownContainer.style.borderRadius = "8px";
    },
    setupClearButton() {
      this.clearButton.addEventListener("click", (e) => this.removeObject(e));
    },
    removeObject() {
      //this.aadressSearch.setAddress("");
      this.activatePlaceholder();
      this.addressSelected = false;
    },
    async getDevelopment() {
      let chosenObject = this.$route.params.id;
      console.log("vidun", chosenObject);

      const response = await axios.get(`/api/development/${chosenObject}`);
      const development = response.data;
      this.form = development;
      this.form.city = development.address.city;
      this.form.county = development.address.county;
      this.form.street = development.address.street;
      this.form.district = development.address.district;
      this.form.ehakCode = development.address.ehakCode;
      this.form.ehrNumber = development.address.ehrNumber;
      this.form.houseNumber = development.address.houseNumber;
      this.form.cadastralNumber = development.address.cadastralNumber;

      this.form.isWatermarkApplied = response.data.isWatermarkApplied;
      if (this.form.address.coordinates) {
        this.mapCoordinates = this.form.address.coordinates;
        this.markerCoordinates = this.form.address.coordinates;
      }
      this.selectAddressFields();

      this.updateHTML++;
    },

    selectAddressFields(reset = false) {
      if (this.form.county) {
        this.counties.forEach((county) => {
          if (county.name === this.form.county) {
            this.selectCounty(county, reset);
          }
        });
      }
      if (this.form.city) {
        this.cities.forEach((city) => {
          if (city.name === this.form.city) {
            this.citiesDisabled = false;
            this.selectCity(city);
          }
        });
      }
      if (this.form.district) {
        this.districts.forEach((district) => {
          if (district.name === this.form.district) {
            this.districtsDisabled = false;

            this.selectDistrict(district);
          }
        });
      }
    },
    selectCounty(county, reset = true) {
      if (this.edit || this.addToProject) {
        this.countiesDisabled = false;
      }
      this.selectedCounty = county;
      if (county.value === "county") {
        this.form.county = null;
        this.form.city = null;
        this.county = null;
        this.city = null;
        this.cityPart = null;
        this.district = null;
        this.form.district = null;
        this.form.cityPart = null;
        this.citiesUpdater++;
      } else {
        this.form.county = county.name;
        this.county = county;
        if (reset) {
          this.form.city = null;
          this.form.district = null;
          this.form.cityPart = null;
          this.city = null;
          this.district = null;
          this.cityPart = null;
          this.selectedCity = null;
          this.selectedDistrict = null;
          this.citiesUpdater++;
        }
      }
      this.countiesUpdater++;
      this.districtsUpdater++;
    },
    selectCity(city) {
      this.selectedCity = city;
      if (city.value === "city") {
        this.form.city = null;
        this.city = null;
      } else {
        this.form.city = city.name;
        this.city = city;
      }
      this.selectedDistrict = null;
      this.citiesUpdater++;
      this.districtsUpdater++;
    },
    selectDistrict(newDistrict) {
      if (newDistrict.value === "district") {
        this.form.district = null;
        this.district = null;
      } else {
        console.log(newDistrict);
        this.district = newDistrict;
        this.form.district = newDistrict.name;
        this.form.ehakCode = newDistrict.value;
      }
      this.selectedDistrict = newDistrict;
    },
    enableCities() {
      this.citiesDisabled = false;
    },
    disableCities() {
      this.citiesDisabled = true;
    },
    enabledistricts() {
      this.districtsDisabled = false;
    },
    disabledistricts() {
      this.districtsDisabled = true;
    },

    onlyNumber($event) {
      // added keycode from 96 to 105 as numpad numbers (needs to be tested in keyboard with numpad)
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
        $event.preventDefault();
      }
    },
    onlyNumberAndComa($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 46 &&
        keyCode !== 44 &&
        (keyCode < 96 || keyCode > 105)
      ) {
        $event.preventDefault();
      }
    },

    setMarker(e) {
      this.markerCoordinates = e.latLng;
    },
    updateMarker(location) {
      this.markerCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    containsWord(str, word) {
      return str.match(new RegExp("\\b" + word + "\\b")) != null;
    },
    containsDot($event) {
      if ($event.key === ",") {
        $event.preventDefault();
      }
    },

    formatDate(e) {
      var allDate = e.split(" ");
      var thisDate = allDate[0].split("-");
      var newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");
      return newDate;
    },
    putImages(e) {
      this.form.images = e;
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },

    async saveListing(listing) {
      this.errors = false;

      if (this.isLoading) return;
      this.isLoading = true;

      if (this.form.realAdress === undefined) {
        this.form.realAddress = {
          cadastralNumber: this.form.cadastralNumber,
          street: this.form.street,
          houseNumber: this.form.houseNumber,
          apartmentNumber: this.form.apartmentNumber,
          county: this.form.county,
          city: this.form.city,
          district: this.form.district,
          cityPart: this.form.cityPart,
        };
      }
      let addressField2 = this.form.realAddress;
      let addressField = this.form.realAddress.address;
      let dealFilled = false;
      let addressFilled = false;
      dealFilled = true;

      if (
        addressField ||
        Object.values(addressField2).some((item) => item != null && item !== "")
      ) {
        addressFilled = true;
        this.isErrorAddress = false;
      } else {
        addressFilled = false;
        this.isErrorAddress = true;
      }
      if (
        (dealFilled && addressFilled) ||
        (this.user.config && addressFilled)
      ) {
        listing.coordinates = this.markerCoordinates;

        let saveResponse = null;

        if (this.edit) {
          saveResponse = await axios.patch("/api/development/listing", listing);
          if (saveResponse.data === 0) {
            this.toastError(this.$t("ErrorUpdating"));
            return;
          } else {
            this.isLoading = false;
            this.toastSuccess(
              this.$t("Listing") + " " + this.$t("Updated").toLowerCase()
            );

            this.$router.back();
            return;
          }
        }
      }
    },
  },

  chooseBox1(choice) {
    this.dealTypes.forEach((deal) => {
      if (deal.value == choice) {
        deal.isChosen = true;
      } else {
        deal.isChosen = false;
      }
    });
  },
  chooseBox2(choice) {
    this.estateTypes.forEach((type) => {
      if (type.value == choice) {
        type.isChosen = true;
      } else {
        type.isChosen = false;
      }
    });
    this.updateHTML++;
  },
  chooseBox3(choice) {
    this.languageTypes.forEach((type) => {
      if (type.value == choice) {
        type.isChosen = true;
      } else {
        type.isChosen = false;
      }
    });
    this.updateHTML++;
  },
  chooseSection2(choice) {
    this.sectionTypes.forEach((type) => {
      if (type.value == choice) {
        type.isChosen = true;
      } else {
        type.isChosen = false;
      }
    });
    this.updateHTML++;
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-start;
  padding-left: 156px;
  padding-right: 88px;
  padding-top: 40px;
}

.content {
  display: block;
  padding-left: 156px;
  padding-right: 88px;
  padding-bottom: 48px;
  background-color: #f4f5f7;
  margin-top: 24px;
  min-width: 760px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 960px;
}

.section-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 32px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 960px;
  min-height: 220px;
}

.additional-info-gaps {
  justify-content: space-between;
  width: 50%;
}

.subheading {
  margin-top: 32px !important;
}

.subheading-autosearch-address {
  margin-top: 32px !important;
  max-width: 896px;
}

.first-general-column {
  padding: 0px;
}

.second-general-column {
  padding: 0px;
}

.example {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a;
}

.subheading-additionalInfo {
  padding-bottom: 12px;
  width: 25%;
}

.autocomplete-row {
  width: 100%;
  flex-direction: column;
}

.autocomplete-item {
  z-index: 4;
  width: 408px;
}

.autocomplete-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin-top: 32px !important;
  position: static;
  width: 896px;
  left: 32px;
  top: 88px;
  background: #f4f5f7;
  border-radius: 8px;

  img {
    margin-right: 8px;
  }
}

.deal-name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.autocomplete-frame-address {
  display: flex;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 8px;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
}

.autocomplete-address {
  width: 3rem !important;
}

#map {
  padding-top: 4px;
}

.balcony-row {
  width: 432px;
  padding-top: 26px;
  //margin-top: 30px;
}

.kitchen-row {
  width: 437px;
  margin-bottom: -16px;
}

.ownership-row {
  max-width: 230px;
}

#detailView {
  padding-bottom: 24px;
  height: 40px;
}

.line-breaker {
  width: 100%;
  margin-top: -16px;
  margin-bottom: 16px;
}

#boardView {
  margin-left: 24px;
}

#detailView,
#boardView {
  cursor: pointer;
  margin-top: 12px !important;
}

.active-view {
  border-bottom: 2px solid black;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  margin-left: 16px;
}

.statusLabel.booked-label {
  border-radius: 4px 0 0 4px;
}

.booked-info-label {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  min-height: unset;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

.booking-until-date {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.box-wrapper {
  gap: 8px;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
}

.date-available-row {
  width: 432px;
  height: 52px !important;
  margin-top: 12px !important;
}

.big-box1,
.big-box2 {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 16px 20px;

  background: #ffffff;

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
}

.big-box1-white {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 16px 20px;

  background: #ffffff;
  color: white !important;

  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
}

.big-box2-white {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 40px;

  flex-direction: column;
  padding: 32px 40px;
  color: white !important;

  border-radius: 8px;
  cursor: pointer;
}

.icon-box {
  padding: 16px;
  padding-left: 16px;

  .row {
    //justify-content: left;
  }
}

.big-box2-image {
  z-index: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: transparent;
}

.big-box3-image {
  z-index: 0;
  width: 16.67px;
  height: 16.67px;
  margin-right: 8px;
  background: transparent;
}

.big-box2-image2 {
  z-index: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  align-items: center;
  background: transparent;
}

.big-box2-image-white {
  background: transparent;
  filter: invert(96%) sepia(100%) saturate(18%) hue-rotate(299deg)
    brightness(105%) contrast(103%);
}

.active-box {
  border: 1px solid #ff5c01;
  background: #ff5c01;
}

.divider {
  width: 100%;
  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
}

.floor-items {
  gap: 12px;
  flex-wrap: nowrap;
}

.circle-number {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  background: #000;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding-top: 2px;
  font-size: 12px;
}

/*.short-term-rent-option-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}*/
.no-active {
  //width: 276px !important;
  height: 52px !important;
}

.deal-name-item {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.no-active2 {
  max-width: 210px !important;
  height: 52px !important;
}

::v-deep .delete {
  padding: 0;
}

::v-deep .plus {
  padding-top: 1px;
}

.house-numbers {
  padding: 0px;
}

.cadastral-numbers {
  padding: 0px;
}

.no-active3 {
  width: 800px !important;
}

.additional-first-row {
  padding-left: 0px;
  padding-top: 32px;
}

/* ::v-deep .inaadressSearchContainer {
  background: white !important;
} */
.additional-second-row {
  padding-top: 32px;
  padding-left: 18px;
}

.additional-third-row {
  margin-top: 6px;
  padding-left: 0px;
}

.buttons-row {
  display: flex;
  flex-direction: row;
  max-width: 960px;
  height: 104px;
  gap: 8px;
  padding: 32px 32px 32px;
  background-color: white;
  border-radius: 8px;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.floor-row {
  height: 52px;
  width: 144px;
  width: 30%;
}

/*  ::v-deep .inaadressSearchContainer {
  background: #f4f5f7 !important;
}  */

::v-deep .vue-map {
  border-radius: 8px;
}

.content-small-green {
  color: #0aaf60;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-pink {
  color: #f81ca0;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-font {
  background: #f2ffe0;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pink-font {
  background: #fff2f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.price-per-unit-text {
  align-self: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #000000;
}

::v-deep .ui-filterable {
  border: 1px solid #e6e8ec !important;
  border-radius: 8px;
  background: white;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

::v-deep .ui-input-clear,
::v-deep .inAdsCopyright {
  display: none;
}

::v-deep #InAadressDiv {
  z-index: 5;
}

::v-deep .inads-input-div {
  border: 1px solid rgb(230, 232, 236);
  border-radius: 8px;
  cursor: text;
  height: 52px !important;
  padding: 7px 11px;
  display: flex;
  margin-top: 0;
}

::v-deep .inads-input-div label {
  font-family: Inter, sans-serif !important;
  line-height: 20px;
  cursor: text;
}

::v-deep .inads-input {
  border: none;
  height: 0;
  padding: 0 !important;
  position: unset !important;
}

::v-deep .inads-copyright {
  display: none !important;
}

::v-deep .inads-input-search {
  display: none;
}

::v-deep .inads_spinner {
  display: none;
}

::v-deep .inads-input-clear {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-image: url("../../../../assets/images/close_grey.svg");
  background-size: 24px 24px;
}

::v-deep .inads-result li:first-child {
  border-radius: 8px 8px 0 0;
}

::v-deep .inads-result li:only-child {
  border-radius: 8px;
}

::v-deep .inads-result li:last-child {
  border-radius: 0 0 8px 8px;
}

::v-deep .inads-result li:hover {
  background-color: #f4f5f7;
  color: black !important;
}

::v-deep .inads-result li {
  padding: 7px 11px;
}

::v-deep .inads-result li span {
  font-family: Inter, sans-serif !important;
  font-size: 14px;
}

.address-div {
  width: 100%;
  height: 52px !important;
}

.address-selected {
  padding-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  min-width: 432px;
  max-width: 432px;
  height: 52px;
  padding: 0px !important;
  padding-bottom: 0px !important;

  &:focus {
    outline: none !important;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 999;
  left: 198px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 16px;
  -webkit-min-logical-width: calc(100% - 16px);
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 16px;
}

/* input#session-date {
    display: inline-block;
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */
.image-uploader {
  //min-width: 896px;
  min-height: 98px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #75787a;
  letter-spacing: 0em !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

#textarea {
  width: 100%;
  height: 182px;
}

.cancel {
  background-color: transparent;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  padding: 12px 12px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  align-items: center;
  display: flex;
  font-family: Inter;
}

.delete-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .gray-border {
  .ui-filterable {
    border: 2px solid #e6e8ec !important;
  }
}

::v-deep .ui-page-theme-a {
  background-color: transparent !important;
}

::v-deep
  .ui-popup-container
  ::v-deep
  .ui-popup-hidden
  ::v-deep
  .ui-popup-truncate {
  display: none !important;
}

::v-deep
  .inAadressInfo
  .ui-page-theme-a
  .ui-popup
  .ui-body-inherit
  .ui-overlay-shadow
  .ui-corner-all {
  background: #f4f5f7 !important;
}

::v-deep .black--text {
  color: #ff5c01 !important;
  caret-color: #ff5c01 !important;
}

::v-deep .theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ff5c01 !important;
}

.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;

  p {
    color: white;
  }
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
}

::v-deep .active-marketplaces .theme--light.v-input {
  color: #e6e8ec !important;
}

.clickable {
  cursor: pointer;
}

::v-deep
  .active-marketplaces
  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
  .v-icon {
  color: rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
}

.link-icon {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.object-address-container {
  width: 100%;
}

::v-deep .object-address-container .ui-input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &:after {
    margin-right: 12px;
    margin-top: 6px;
    content: url("../../../../assets/images/search.svg");
  }
}

::v-deep .object-address-container .inaadressSearch,
::v-deep .object-address-container .ui-input-search {
  ul {
    //border: 1px solid #e6e8ec !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: white !important;
  }

  input {
    width: 80% !important;
    padding: 8px 12px;
    height: 52px;

    &:focus-visible {
      outline: none;
      border: none;
      //background-color: white !important;
    }

    &::after {
      content: url("../../../../assets/images/Dollar.svg");
    }
  }
}
</style>
